import React, { Component } from 'react'
import 'antd/dist/antd.css';
// import './../index.scss';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
// import { animateScroll as scroll } from "react-scroll"
import Typography from 'antd/es/typography'
import 'antd/dist/antd.css';
const { Title } = Typography;

class Carabayar extends Component {
	render() {
		return (
            <Row> 
            <Col xl={{ span: 20, offset: 2 }} lg={{ span: 22, offset: 1 }} md={{ span: 22, offset: 1 }} sm={{ span: 22, offset: 1 }} xs={{ span: 22, offset: 1 }} className='text-footer' style={{textAlign: 'left', padding: '50px 20px'}}>
            <Title level={2}>Kebijakan Privasi </Title>
            <b style={{color: '#6308ff', fontWeight: 'lighter'}}> Tanggal Berlaku: 1 Januari 2020 </b><br /><br />
            <p> Privasi Anda penting bagi PintuKelas, sehingga kami telah mengembangkan Kebijakan Privasi yang berisi cara pengumpulan, penggunaan, pengungkapan, pengalihan, dan penyimpanan informasi pribadi Anda. Sebagai pengguna, Anda dianjurkan untuk membaca Kebijakan Privasi ini dengan seksama untuk memastikan bahwa Anda memahami bagaimana ketentuan Kebijakan Privasi ini kami berlakukan. </p>
            <Title level={4}> <b style={{color: '#6308ff'}}>1. Pengumpulan dan Penggunaan Informasi Pribadi </b></Title>
            <p style={{marginLeft: '10px'}}><b> Informasi Pribadi</b> adalah data yang dapat digunakan untuk mengenali atau menghubungi seseorang seperti: Nomor Induk Siswa, Nama Siswa, Tingkat Pendidikan/Kelas Siswa, Nama Orangtua, Nomor Ponsel, Email, dan No. Virtual Account dan informasi lain yang mungkin dapat mengidentifikasi Anda sebagai pengguna PintuKelas. 
            PintuKelas dapat saja meminta Informasi Pribadi saat Anda menghubungi PintuKelas atau pun mitra yang bekerja sama dengan PintuKelas. Informasi Pribadi yang diperoleh PintuKelas bersama mitranya dapat saling dibagi dan digunakan sesuai Kebijakan Privasi ini. Mereka juga dapat menggabungkannya dengan informasi lain untuk menyediakan dan menyempurnakan produk, layanan, konten, serta promosi dari mitra PintuKelas. Anda dapat tidak memberikan Informasi Pribadi yang diminta, tetapi sebagai konsekuensinya, ada kemungkinan PintuKelas tidak dapat menyediakan produk dan layanan maupun menjawab pertanyaan Anda. 
            Berikut adalah contoh jenis Informasi Pribadi yang dapat dikumpulkan PintuKelas serta penggunaannya: <br /><br />
            <b> Informasi Pribadi yang Dikumpulkan  </b><br />
                <ul>
                    <li>
                    Ketika Anda mengaktifkan akun PintuKelas, mengunduh pembaruan perangkat lunak, menghubungi 	PintuKelas melalui media sosial, atau berpartisipasi dalam survei online, berbagai Informasi Pribadi milih 	Anda, termasuk nama, no KTP, NPWP, pengenal perangkat, alamat IP, informasi lokasi, dan informasi profil 	tempat kontak tersebut berada melalui media sosial dapat dikumpulkan PintuKelas. 
                    </li>
                    <li>
                    Bila Anda berbagi konten PintuKelas dengan keluarga dan teman, atau mengajak orang lain untuk 	berpartisipasi dalam layanan maupun acara yang melibatkan PintuKelas, maka informasi yang Anda 	berikan tentang mereka seperti nama, nomor telepon dan alamat email juga dapat dikumpulkan 	PintuKelas. Informasi tersebut akan digunakan untuk memenuhi permintaan Anda, menyediakan layanan 	yang relevan, atau untuk mencegah penipuan.
                    </li>
                </ul>

                <b> Bagaimana Informasi Pribadi Anda Digunakan  </b><br />
                <p> Informasi Pribadi Anda digunakan untuk tujuan yang dijelaskan dalam Kebijakan Privasi ini dengan persetujuan Anda sebagai syarat kepatuhan wajib hukum yang harus ditaati oleh PintuKelas atau ketika dinilai telah diperlukan untuk tujuan dan kepentingan sah yang diupayakan oleh PintuKelas atau pihak ketiga yang mungkin memerlukan pengungkapan informasi dari PintuKelas. </p>
                <ul>
                    <li>
                    Informasi Pribadi yang dikumpulkan memungkinkan PintuKelas untuk terus mengabari Anda tentang 	pemberitahuan produk terkini, pembaruan perangkat lunak, dan acara mendatang yang melibatkan 	PintuKelas. 
                    </ li>
                    <li>
                    Informasi Pribadi juga digunakan untuk membantu, membuat, mengembangkan, mengoperasikan, 	memberikan, dan meningkatkan produk, layanan, konten, serta promo marketing mitra PintuKelas, dan 	untuk mencegah penipuan identitas. PintuKelas juga dapat menggunakan Informasi Pribadi untuk tujuan 	keamanan akun dan jaringan, termasuk untuk melindungi pelayanan demi keuntungan semua pengguna. 	PintuKelas membatasi akses data dalam kepentingan sah untuk melindungi pengguna dan layanan 	PintuKelas. 
                    </ li>
                    <li>
                    Informasi Pribadi Anda dapat digunakan untuk keperluan verifikasi identitas, membantu mengidentifikasi 	pengguna, atau menentukan promosi pemasaran yang tepat. 
                    </ li>
                    <li>
                    Dari waktu ke waktu, PintuKelas dapat menggunakan Informasi Pribadi untuk mengirimi Anda 	pemberitahuan penting seperti komunikasi tentang perubahan persyaratan dan ketentuan, serta 	kebijakan kami. Karena informasi ini penting bagi interaksi Anda dengan PintuKelas, Anda tidak dapat 	memilih untuk berhenti menerima informasi ini. 
                    </ li>
                    <li>
                    Informasi Pribadi juga dapat digunakan untuk tujuan internal seperti audit, analisis data, dan penelitian 	guna menyempurnakan produk, layanan, serta komunikasi pelanggan PintuKelas. 
                    </li>
                </ul>

                <b> Sumber Informasi Pribadi yang Tidak Dikumpulkan dari Anda   </b><br />
                <p> Sebagai orang tua yang putra/putrinya bersekolah di institusi yang bekerja sama dengan PintuKelas dalam penggunaan produk dan layanan PintuKelas, Informasi Pribadi Anda mungkin saja telah diterima PintuKelas dari institusi pendidikan tersebut. Informasi Pribadi Anda tersebut meliputi Nomor Induk Siswa, Nama Siswa, Tingkat Pendidikan/Kelas Siswa, Nama Orangtua, Nomor Ponsel, Email, dan No. Virtual Account, yang digunakan untuk keperluan pendaftaran pengguna produk dan layanan PintuKelas. 
                Informasi Pribadi Anda juga dapat diperoleh dari pihak lain apabila pihak tersebut telah mendaftarkan Anda untuk menerima produk dan layanan atau berpartisipasi di acara yang melibatkan PintuKelas. Informasi yang Anda berikan ketika mengaktifkan akun PintuKelas akan divalidasi untuk tujuan keamanan dan pencegahan penipuan.
                Dari Informasi Pribadi yang diterima, PintuKelas juga dapat menghubungi Anda untuk menginformasikan promosi dari mitra PintuKelas. Jika Anda tidak memberi Informasi Pribadi secara langsung namun PintuKelas tetap dapat menghubungi Anda, sumber informasi akan diinformasikan kepada Anda saat kami pertama kali Anda dihubungi terkait promosi tersebut.
                </p>
            </p>
            <Title level={4}> <b style={{color: '#6308ff'}}>2. Pengumpulan dan Penggunaan Informasi Non-Pribadi  </b></Title>
            <p style={{marginLeft: '10px'}}>PintuKelas dapat mengumpulkan, menggunakan, mengalihkan, dan mengungkapkan Informasi Non- Pribadi untuk tujuan apa pun. Berikut adalah beberapa contoh Informasi Non-Pribadi yang dikumpulkan serta penggunaannya: 
            </p>
            <ul>
                <li>
                Informasi seperti tagihan virtual account dari orang tua siswa, laporan transaksi pembayaran virtual 	account dari mitra bank, informasi akademik, pengenal perangkat unik, dan zona waktu saat PintuKelas 	digunakan dapat diutilisasi pihak PintuKelas agar dapat memahami perilaku pengguna dengan lebih baik 	serta menyempurnakan produk, layanan, dan konten promosi. 
                </li>
                <li>
                PintuKelas dapat mengumpulkan informasi terkait aktivitas pengguna dari produk dan layanan PintuKelas. 	Informasi ini digabungkan dan digunakan agar PintuKelas dapat menyediakan informasi yang lebih 	bermanfaat kepada pengguna serta dapat memahami fitur paling tepat dari produk dan layanan 	PintuKelas. Data yang dikumpulkan dianggap sebagai Informasi Non-Pribadi untuk keperluan Kebijakan 	Privasi ini. 
                </li>
            </ul>
            <p style={{marginLeft: '10px'}}>Jika Informasi Non-Pribadi dan Informasi Pribadi digabungkan, maka informasi gabungan tersebut akan diperlakukan sebagai Informasi Pribadi selama tetap dalam gabungan.  
            </p>

            <Title level={4}> <b style={{color: '#6308ff'}}>3. Pengungkapan kepada Pihak Ketiga   </b></Title>
            <p style={{marginLeft: '10px'}}>PintuKelas dapat memberikan Informasi Pribadi tertentu kepada pihak ketiga untuk menyediakan atau meningkatkan mutu produk dan layanan PintuKelas, termasuk membagikan Informasi Pribadi dengan mitra bank yang memberikan layanan virtual account dalam kaitannya dengan tagihan dari pihak institusi pendidikan. Dalam konteks tersebut, PintuKelas meminta pihak ketiga tersebut untuk menanganinya sesuai dengan hukum terkait. 
            </p>
            <p style={{marginLeft: '10px'}}><b>Lainya</b></p>
            <p style={{marginLeft: '10px'}}>Undang-undang, proses hukum, litigasi, dan/atau permintaan dari lembaga publik maupun pemerintah di dalam atau luar negara domisili Anda mungkin mengharuskan PintuKelas mengungkapkan informasi pribadi Anda. PintuKelas juga dapat mengungkapkan informasi tentang Anda jika bertujuan untuk keamanan nasional, penegakan hukum, atau masalah lain demi kepentingan publik harus atau perlu dilakukan. PintuKelas dapat mengungkapkan informasi Anda, hanya jika ada dasar yang sah untuk melakukannya dan telah ditentukan bahwa pengungkapan diperlukan untuk menegakkan Syarat & Ketentuan PintuKelas atau melindungi operasional dan pengguna PintuKelas. Hal ini dapat termasuk memberikan informasi kepada otoritas publik atau pemerintah. Selain itu, jika terjadi reorganisasi, merger, atau penjualan, PintuKelas dapat mengalihkan setiap dan semua informasi pribadi yang telah dikumpulkan ke pihak ketiga terkait. 
            </p>

            <Title level={4}> <b style={{color: '#6308ff'}}>4. Perlindungan Informasi Pribadi    </b></Title>
            <p style={{marginLeft: '10px'}}>Ketika Anda menggunakan produk atau layanan PintuKelas dan mengunggah/membagikannya di ruang obrolan daring atau layanan jejaring sosial, informasi pribadi dan konten yang Anda bagikan dapat dilihat oleh pengguna lain dan dapat dibaca, dikumpulkan, atau digunakan oleh mereka. Dalam hal ini, Anda bertanggung jawab atas semua Informasi Pribadi yang Anda bagikan. Misalnya, jika Anda mencantumkan nama dan alamat surel dalam unggahan jejaring sosial, maka informasi tersebut akan ditampilkan secara publik. 
            </p>
            <p style={{marginLeft: '10px'}}><b>Integritas dan Penyimpanan Informasi Pribadi </b></p>
            <p style={{marginLeft: '10px'}}>PintuKelas akan memudahkan Anda menjaga keakuratan, kelengkapan, dan keterbaruan Informasi Pribadi Anda. Informasi Pribadi Anda akan disimpan selama periode yang diperlukan guna memenuhi tujuan yang diuraikan dalam Kebijakan Privasi ini. PintuKelas dengan hati-hati memeriksa kebutuhan Informasi Pribadi dan jika memang relevan, informasi akan disimpan dalam periode yang 
            <br />   
            sesingkat mungkin guna merealisasikan tujuan pengumpulan kecuali jika diperlukan periode penyimpanan yang lebih lama oleh hukum. 
            </p>
            <p style={{marginLeft: '10px'}}><b>Akses ke Informasi Pribadi  </b></p>
            <p style={{marginLeft: '10px'}}>Anda dapat turut memastikan keakuratan, kelengkapan, dan kebaruan informasi kontak dan preferensi Anda dengan masuk ke halaman Profil PintuKelas. Untuk Informasi Pribadi lain yang tersimpan, Anda dapat meminta PintuKelas memperbaiki data tersebut melalui pihak institusi pendidikan.
            </p>
            <p style={{marginLeft: '10px'}}><b>Penyimpanan, Pemrosesan, Pemindahan, dan Akses Data Pribadi   </b></p>
            <ul>
                <li>
                a. 	Kami akan menyimpan Informasi Pribadi Anda untuk periode yang diperlukan untuk memenuhi berbagai 	tujuan yang diuraikan dalam Kebijakan Privasi ini dan setiap tujuan usaha atau hukum yang sah, kecuali 	periode penyimpanan/retensi yang lebih lama diizinkan atau diwajibkan oleh peraturan perundang-	undangan. Setelah jangka waktu tersebut, kami akan menghapus dan memusnahkan atau membuat 	anonim semua dokumen yang memuat Informasi Pribadi Anda secara aman dan terjamin. 
                </li>
                <li> 
                b. 	Informasi Pribadi Anda yang Anda berikan kepada kami dapat disimpan, diproses, dipindahkan antara, dan 	diakses dari server Kami maupun dari server konsultan-konsultan Kami, dan/atau pemberi jasa-pemberi 	jasa kami, baik yang terletak di Republik Indonesia maupun negara-negara lain yang memiliki peraturan 	perundang-undangan yang mungkin tidak menjamin tingkat perlindungan Data Pribadi yang sama 	dengan di Republik Indonesia. Namun, Kami akan mengambil langkah sewajarnya untuk memastikan 	bahwa Informasi Pribadi Anda ditangani sesuai dengan Kebijakan Privasi ini, terlepas dari tempat Informasi 	Pribadi Anda disimpan atau diakses. 
                </li>
            </ul>  
            <p style={{marginLeft: '10px'}}> 
            PintuKelas dapat menolak proses permintaan yang tidak serius/menggangu, membahayakan privasi orang 	lain, sangat tidak praktis, atau yang aksesnya tidak diwajibkan oleh hukum setempat. PintuKelas juga 	dapat menolak aspek permintaan akses atau penghapusan jika hal itu dapat menghambat penggunaan 	data PintuKelas yang sah untuk tujuan anti-penipuan dan keamanan seperti yang dijelaskan sebelumnya. 
            </p> 
            <p style={{marginLeft: '10px'}}><b>Situs dan Layanan Pihak Ketiga  </b></p>
            <p style={{marginLeft: '10px'}}>Produk dan layanan PintuKelas dapat berisi tautan ke situs web, produk, maupun layanan pihak ketiga. Produk dan layanan PintuKelas juga mungkin menggunakan atau menawarkan produk maupun layanan dari pihak ketiga, misalnya laporan transaksi pembayaran virtual account dari mitra bank. 
            <br />
            Informasi yang dikumpulkan pihak ketiga, yang mungkin menyertakan hal seperti data lokasi atau detail kontak, diatur oleh praktik privasi mereka. Anda dianjurkan untuk mempelajari praktik privasi milik pihak ketiga tersebut. 
            </p>
            <p style={{marginLeft: '10px'}}><b>Keamanan  </b></p>
            <p style={{marginLeft: '10px'}}>Kerahasiaan informasi pribadi Anda adalah hal yang terpenting bagi PintuKelas. Segala upaya dan langkah terbaik akan dilakukan untuk melindungi dan mengamankan data dan informasi pribadi Anda. Akan tetapi, PintuKelas tidak dapat sepenuhnya menjamin bahwa sistem tidak akan dapat ditembus sama sekali akibat adanya virus, malware, gangguan atau kejadian luar biasa termasuk <br />

            akses tanpa otorisasi oleh pihak ketiga. Anda tidak boleh mengungkapkan PIN atau kata sandi akun Anda kepada siapapun dan harus senantiasa menjaga keamanan perangkat yang Anda gunakan. 

            </p>
            <p style={{marginLeft: '10px'}}><b>Perubahan atas Kebijakan Privasi   </b></p>
            <p style={{marginLeft: '10px'}}>PintuKelas dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Bila terdapat perubahan penting pada kebijakan, pemberitahuan akan dikirimkan ke alamat surel Anda bersama Kebijakan Privasi yang telah diperbarui. 
            </p>

            <p style={{marginLeft: '10px'}}><b>Kepatuhan Kepada Ketentuan Peraturan Perundang-Undangan Yang Berlaku   </b></p>
            <ul>
                <li>
                a. 	Kebijakan Privasi ini tunduk pada hukum Negara Republik Indonesia. 
                </li>
                <li>
                b. 	Kami mematuhi ketentuan peraturan perundang-undangan yang berlaku di Republik Indonesia, dan kami 	(termasuk konsultan-konsultan kami, dan/atau pemberi jasa-pemberi jasa kami) dapat dan akan 	mengungkapkan data dan informasi apa pun, termasuk Data Pribadi Anda, kepada pemerintah atau 	aparat penegak hukum apabila diminta secara sah oleh pengadilan berdasarkan hukum yang berlaku di 	Republik Indonesia.  
                </li>
            </ul>  
            <p style={{marginLeft: '10px'}}><b>Hubungi Kami   </b></p>
            <p style={{marginLeft: '10px'}}>Jika Anda memiliki pertanyaan atau masalah tentang Kebijakan Privasi atau pemprosesan data PintuKelas silahkan menghubungi kami dengan mengirimkan surel ke halo@pintukelas.com 
            </p>
            </Col>
            </Row>
		)
	}
}

export default Carabayar

