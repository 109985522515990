import React, { Component } from "react";
import { Typography } from "antd";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { animateScroll as scroll } from "react-scroll";
import { withRouter } from "react-router-dom";
import email from "../../assets/images/email.png";
import location from "../../assets/images/location.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import privacy from "../../assets/images/privacy.png";
import icTermConditions from "../../assets/icons/icTermCondition.svg";
const { Title } = Typography;

class Contact extends Component {
    state = {};
    privacys = () => {
        this.props.history.push("/privacy");
        this.scrollToTop();
    };
    termsAndConditions = () => {
        this.props.history.push("/terms-condition");
        this.scrollToTop();
    };
    scrollToTop = () => {
        scroll.scrollToTop();
    };
    render() {
        return (
            <div
                className="bg-gradient"
                style={{
                    padding: "50px 24px",
                    textAlign: "left",
                    color: "white"
                }}
                id={this.props.id}
            >
                <Row>
                    <Col
                        xl={{ span: 7, offset: 2 }}
                        md={{ span: 8, offset: 1 }}
                        sm={{ span: 12, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                    >
                        <Title
                            level={4}
                            id={this.props.id}
                            style={{ color: "white" }}
                        >
                            Contact Us
                        </Title>
                        <p>
                            <a
                                className="primary-color"
                                href="mailto:halo@pintukelas.com"
                                style={{ color: "white" }}
                            >
                                <img
                                    src={email}
                                    alt=""
                                    style={{ height: "30px", padding: "9px" }}
                                />
                                halo@pintukelas.com
                            </a>
                        </p>
                        <p>
                            <img
                                src={location}
                                alt=""
                                style={{
                                    height: "35px",
                                    padding: "9px",
                                    verticalAlign: "top"
                                }}
                            />
                            <span
                                style={{
                                    display: "inline-block",
                                    justifyContent: "center"
                                }}
                            >
                                {" "}
                                Wework Noble House lt. 29 <br />
                                Jalan Dr.Ide Anak Agung Gde Agung Kav. E 4.2,{" "}
                                <br /> RT.5/RW.2, Kuningan, Kuningan Tim.,
                                Jakarta
                                <br /> Jakarta Selatan, DKI Jakarta 12950
                            </span>
                        </p>
                    </Col>
                    <Col
                        xl={{ span: 5, offset: 1 }}
                        md={{ span: 5, offset: 1 }}
                        sm={{ span: 8, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                    >
                        <Title
                            level={4}
                            id={this.props.id}
                            style={{ color: "white" }}
                        >
                            Follow Us
                        </Title>
                        <p>
                            <a
                                href="https://www.instagram.com/pintu_kelas/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                            >
                                <img
                                    src={facebook}
                                    alt=""
                                    style={{ height: "35px", padding: "9px" }}
                                />
                                pintu_kelas
                            </a>
                        </p>
                        <p>
                            <a
                                href="http://facebook.com/PintuKelas/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "white" }}
                            >
                                <img
                                    src={instagram}
                                    alt=""
                                    style={{ height: "35px", padding: "9px" }}
                                />
                                Pintu_Kelas
                            </a>
                        </p>
                    </Col>
                    <Col
                        xl={{ span: 5, offset: 1 }}
                        md={{ span: 5, offset: 1 }}
                        sm={{ span: 8, offset: 1 }}
                        xs={{ span: 24, offset: 0 }}
                    >
                        <Title
                            level={4}
                            id={this.props.id}
                            style={{ color: "white" }}
                        >
                            Information
                        </Title>
                        <p>
                            <a
                                onClick={this.privacys}
                                style={{ color: "white" }}
                            >
                                <img
                                    src={privacy}
                                    alt=""
                                    style={{ height: "35px", padding: "9px" }}
                                />
                                Privacy Policy
                            </a>
                        </p>
                        <p>
                            <a
                                onClick={this.termsAndConditions}
                                style={{ color: "white" }}
                            >
                                <img
                                    src={icTermConditions}
                                    alt=""
                                    style={{ height: "35px", padding: "9px" }}
                                />
                                Terms and Conditions
                            </a>
                        </p>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default withRouter(Contact);
