import React, { Component } from "react";
import { Typography, Row } from "antd";
import Col from "antd/es/col";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import pushremindersvg from "../../assets/images/icon_16.png";
import detailedsvg from "../../assets/images/icon_17.png";
import anytimesvg from "../../assets/images/icon_18.png";
import groupImage from "../../assets/images/group.png";
import appstore from "../../assets/images/appstore.png";
import googleplay from "../../assets/images/googleplay.png";

import detectionIos from "../../utils/detection-ios";
const { Title } = Typography;

class Home extends Component {
    render() {
        const link = detectionIos()
            ? "https://apps.apple.com/id/app/pintukelas/id1495610556"
            : "https://play.google.com/store/apps/details?id=co.onelabs.pintukelas";

        return (
            <div
                style={{
                    background: "#fff",
                    padding: "30px 14px",
                    minHeight: "",
                    textAlign: "left"
                }}
                id={this.props.id}
            >
                <Row>
                    <Col
                        xl={{ span: 10, offset: 0 }}
                        md={{ span: 11, offset: 1 }}
                        sm={{ span: 0, offset: 0 }}
                        xs={{ span: 0, offset: 0 }}
                        style={{ paddingTop: "100px" }}
                    >
                        <ScrollAnimation animateIn="fadeInDown" animateOnce>
                            <Title level={2}>
                                Knowing Your <br /> Kid’s Potential Closer.
                            </Title>
                            <p style={{ fontSize: "16px" }}>
                                PintuKelas is a digital solution for school and
                                parent to help them easier manage their
                                administrative and academic activities.
                            </p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                            <div style={{ padding: "20px 10px 0px" }}>
                                <h4>Available Now</h4>
                            </div>
                            <Col
                                xl={{ span: 8, offset: 0 }}
                                lg={{ span: 8, offset: 0 }}
                                md={{ span: 10, offset: 0 }}
                            >
                                <img
                                    src={googleplay}
                                    className="ic-store"
                                    alt=""
                                    onClick={() => window.open(link, "_blank")}
                                />
                            </Col>
                            <Col
                                xl={{ span: 8, offset: 1 }}
                                lg={{ span: 8, offset: 1 }}
                                md={{ span: 10, offset: 1 }}
                            >
                                <img
                                    src={appstore}
                                    className="ic-store"
                                    alt=""
                                    onClick={() => window.open(link, "_blank")}
                                />
                            </Col>
                        </ScrollAnimation>
                    </Col>

                    <Col
                        xl={{ span: 10, offset: 2 }}
                        md={{ span: 11, offset: 1 }}
                        sm={{ span: 20, offset: 2 }}
                        xs={{ span: 20, offset: 2 }}
                    >
                        {/* <ScrollAnimation animateIn="fadeInDown"> */}
                        <img
                            src={groupImage}
                            alt=""
                            style={{ width: "100%" }}
                        />
                        {/* </ScrollAnimation> */}
                    </Col>

                    <Col
                        xl={{ span: 0, offset: 0 }}
                        md={{ span: 0, offset: 0 }}
                        sm={{ span: 20, offset: 2 }}
                        xs={{ span: 22, offset: 1 }}
                        style={{ padding: "50px 0px" }}
                    >
                        <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                            <Title level={2}>
                                Knowing Your <br /> Kid’s Potential Closer.
                            </Title>
                            <p style={{ fontSize: "16px" }}>
                                PintuKelas is a digital solution for school and
                                parent to help them easier manage their
                                administrative and academic activities.
                            </p>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                            <div style={{ padding: "20px 10px 0px" }}>
                                <h4>Available Now</h4>
                            </div>
                            <Col
                                sm={{ span: 8, offset: 0 }}
                                xs={{ span: 10, offset: 0 }}
                            >
                                <img
                                    src={googleplay}
                                    className="ic-store"
                                    alt=""
                                    onClick={() => window.open(link, "_blank")}
                                />
                            </Col>
                            <Col
                                sm={{ span: 8, offset: 0 }}
                                xs={{ span: 10, offset: 1 }}
                            >
                                <img
                                    src={appstore}
                                    className="ic-store"
                                    alt=""
                                    onClick={() => window.open(link, "_blank")}
                                />
                            </Col>
                        </ScrollAnimation>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col
                        xl={{ span: 24, offset: 0 }}
                        style={{ textAlign: "center", padding: "10px" }}
                    >
                        <ScrollAnimation animateIn="fadeInDown" animateOnce>
                            <Title level={4} style={{ marginTop: "50px" }}>
                                Our Product
                            </Title>
                            <Col
                                xl={{ span: 8, offset: 0 }}
                                md={{ span: 8, offset: 0 }}
                                className="wrap-ic"
                            >
                                <img
                                    src={pushremindersvg}
                                    alt=""
                                    style={{ height: "40px", marginBottom: 10 }}
                                />
                                <Title level={4}>Manage Bill</Title>
                                <ul
                                    className="ul-list"
                                    style={{ textAlign: "center", padding: 0 }}
                                >
                                    <li>* Send Bill To Parent </li>
                                    <li>* Check Payment Status </li>
                                </ul>
                            </Col>
                            <Col
                                xl={{ span: 8, offset: 0 }}
                                md={{ span: 8, offset: 0 }}
                                className="wrap-ic"
                            >
                                <img
                                    src={detailedsvg}
                                    alt=""
                                    style={{ height: "40px", marginBottom: 10 }}
                                />
                                <Title level={4}>Online Reporting</Title>
                                <ul
                                    className="ul-list"
                                    style={{ textAlign: "center", padding: 0 }}
                                >
                                    <li>
                                        * Upload Academic Report To Parents{" "}
                                    </li>
                                </ul>
                            </Col>
                            <Col
                                xl={{ span: 8, offset: 0 }}
                                md={{ span: 8, offset: 0 }}
                                className="wrap-ic"
                            >
                                <img
                                    src={anytimesvg}
                                    alt=""
                                    style={{ height: "40px", marginBottom: 10 }}
                                />
                                <Title level={4}>Digital Bulletin</Title>
                                <ul
                                    className="ul-list"
                                    style={{ textAlign: "center", padding: 0 }}
                                >
                                    <li>* Upload Latest News To All Parents</li>
                                </ul>
                            </Col>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Home;
