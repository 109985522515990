import React from 'react';
import './App.css';
import Prelaunch from '../src/pages/prelaunch'

function App() {
  return (
    <div className="App">
      <Prelaunch></Prelaunch>
    </div>
  );
}

export default App;
