import React, { Component } from 'react'
import { Typography } from 'antd';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import 'antd/dist/antd.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import phoneImage from '../../assets/images/artboard_3.png'

const { Title } = Typography;

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            width: window.innerWidth
        }
    }
    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
		if (window.innerWidth >= 576) {
			this.setState({
				width: window.innerWidth
			});
		} else {
			this.setState({
				width: window.innerWidth
			});
        }
	}
    render() { 
        return ( 
            <div style={{ background: '', padding: '40px 14px 40px 14px', minHeight: '', textAlign: 'left' }} id={this.props.id}>
                <Row gutter={0}>   
                    <ScrollAnimation animateIn="fadeInLeft" animateOnce>       
                    <Col xl={{ span: 9, offset: 4 }} md={{ span: 9, offset: 2 }} sm={{ span: 20, offset: 2 }} xs={{ span: 21, offset: 2 }} className={this.state.width >= 576 ? 'wrap-product-2' : 'wrap-product-sm-2'}>
                        <Title level={4} style={{color: ''}}>
                            Transparent Academic Processes  <span role="img" symbol="" description="aria-label"> 🔎</span>
                        </Title>
                        <p style={{marginTop: '10px', fontSize: '16px'}}>
                        Curious about your kid's grades? What academic material does the school teach? You can monitor it transparently with this feature. Monitor and develop your kids's academic interests now easier than ever!
                        </p>
                    </Col>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInRight" animateOnce> 
                    <Col xl={{ span: 6, offset: 2 }} md={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} xs={{ span: 24, offset: 0 }} style={{textAlign: 'center'}}> 
                        <img src={phoneImage} alt='' style={{width: '100%', padding:'0px'}} />
                    </Col>
                    </ScrollAnimation>
                </Row>           
            </div>
        );
    }
}
 
export default Product;