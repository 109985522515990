import React, { Component } from 'react'
import 'antd/dist/antd.css';
import './index.css';
import imgLogos from '../../assets/images/logo_pintukelas.png';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Button from 'antd/es/button'
import { withRouter } from "react-router-dom";

import { Layout, Menu, Drawer, Icon, List } from 'antd';
import { Link, animateScroll as scroll } from "react-scroll"
import facebook from '../../assets/images/facebook-nav.png'
import instagram from '../../assets/images/instagram-nav.png'
import whitelogo from '../../assets/images/whitelogo.png'
import 'antd/dist/antd.css';

const { Header} = Layout;
 
class Headers extends Component {
    state = {
        current: '1',
        blockBox: false
    }
    scrollToTop = () => {
        this.props.history.push('/')
        scroll.scrollToTop();
    };
    showBlock = () => {
        this.setState({ blockBox: true })
    }
    hideBlock = e => {
        this.setState({ blockBox: false })
    }
    handleClick = () => {
        console.log('gas')
    }
    product = () => {
        this.setState({current: ['2']})
        this.props.history.push('/')
        this.hideBlock()
    }
    contact = () => {
        this.setState({current: ['3']})
        this.props.history.push('/')
        this.hideBlock()
    }
	render() {
		return (
            <Header className="sticky-top">
            <Row>
            <Col xl={{ span: 10, offset: 2 }} md={{ span: 10, offset: 1 }} sm={{ span: 16, offset: 1 }} xs={{ span: 15, offset: 1 }}>
                <div className="logo" onClick={this.scrollToTop} style={{cursor: 'pointer'}}>
                    <img src={imgLogos} alt='' width='120' />
                </div>
            </Col>
            <Col xl={{ span: 5, offset: 0 }} md={{ span: 7, offset: 0 }} sm={{ span: 0, offset: 0 }} xs={{ span: 0, offset: 0 }}>    
                <Menu
                    theme=""
                    mode="horizontal"
                    style={{ lineHeight: '62px', borderBottom: 'none', float: 'right'}}
                    onClick={this.handleClick}>
                    <Menu.Item key="1">
                        <Link
                        activeClass="activeSel"
                        to='product'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={this.product}
                        >
                        Product
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                        <Link
                        activeClass="activeSel"
                        to='contact'
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        onClick={this.contact}
                        >
                        Contact
                        </Link>
                    </Menu.Item>
                </Menu>
              </Col>
              <Drawer
                title="Basic Drawer"
                placement="right"
                closable={false}
                onClose={this.hideBlock}
                visible={this.state.blockBox}
                >
                  <List
                    size="large"
                    >
                      <List.Item>
                        <Link to={'/'} activeClass=""
                        to="product"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500} onClick={this.product} style={{color: 'white', fontWeight: 'bold'}}>{'Product'} 
                        </Link>
                      </List.Item>
                      <List.Item>
                        <Link to={'/'} activeClass=""
                        to="contact"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500} onClick={this.contact} style={{color: 'white', fontWeight: 'bold'}}>{'Contact'} 
                        </Link>
                      </List.Item>
                  </List>
                  <img className='adjustWhiteLogo' src={whitelogo} alt='' />
              </Drawer>

              <Col xl={{ span: 2, offset: 0 }} md={{ span: 3, offset: 0 }} sm={{ span: 4, offset: 0 }} xs={{ span: 4, offset: 0 }} align='right'> 
                    <Col span={12}> 
                        <a href="https://www.instagram.com/pintu_kelas/" target="_blank" rel="noopener noreferrer">
                            <img src={instagram} alt='' style={{height: '35px',padding:'9px',marginTop: '-5px', background: ''}} />
                        </a>
                    </Col> 
                    <Col span={12}> 
                        <a href="http://facebook.com/PintuKelas/" target="_blank" rel="noopener noreferrer"> 
                            <img src={facebook} alt='' style={{height: '35px',padding:'9px',marginTop: '-5px', background: ''}} />
                        </a>
                    </Col>
                    
              </Col>
              <Col xl={{ span: 2, offset: 0 }} md={{ span: 2, offset: 0 }} sm={{ span: 0, offset: 0 }} xs={{ span: 0, offset: 0 }} > 
                <Button onClick={()=> window.open("https://cms.pintukelas.com/", "_blank")} type="primary" shape="round" size='' className='loginButton'> Login</Button>
              </Col>

              <Col xl={{ span: 0, offset: 0 }} md={{ span: 0, offset: 0 }} sm={{ span: 2, offset: 0 }} xs={{ span: 2, offset: 0 }} align='right'>
                  {!this.state.blockBox ?
                    <Button type="link" onClick={this.showBlock}>
					<Icon type="menu" size='large' style={{ fontSize: '18px', color: '#08c' }} />
                    </Button> : <Button type="link" onClick={this.hideBlock}>
                      <Icon type="close" size='large' style={{ fontSize: '18px', color: '#08c' }} />
                    </Button> 
                  }
              </Col>
              </Row>
            </Header>

		)
	}
}

export default withRouter(Headers)
