import React, { Component } from 'react'
import { Typography } from 'antd';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import 'antd/dist/antd.css';

import phoneImage from '../../assets/images/artboard_5.png'

const { Title } = Typography;

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            width: window.innerWidth
        }
    }
    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
		if (window.innerWidth >= 576) {
			this.setState({
				width: window.innerWidth
			});
		} else {
			this.setState({
				width: window.innerWidth
			});
        }
	}
    render() { 
        return ( 
            <div style={{ background: '', padding: '40px 14px 40px 14px', minHeight: '', textAlign: 'left' }} id={this.props.id}>
            <ScrollAnimation animateIn="fadeInUp" animateOnce>  
            <Row gutter={0}>         
                <Col xl={{ span: 9, offset: 3 }} md={{ span: 9, offset: 2 }} sm={{ span: 20, offset: 2 }} xs={{ span: 21, offset: 2 }} className={this.state.width >= 576 ? 'wrap-product-2' : 'wrap-product-sm-2'}>
                    <Title level={4} style={{color: ''}}>
                    Up to Date with <br /> Your kid's School  📰
                    </Title>
                    <p style={{marginTop: '10px', fontSize: '16px'}}>
                    No time for keeping track of what's going on in your kid's school? Our feature can bring you the latest news and information, share photos, and videos from your kid's school.
                    </p>
                </Col>
                <Col xl={{ span: 6, offset: 2 }} md={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} xs={{ span: 24, offset: 0 }} style={{textAlign: 'center'}}> 
                    <img src={phoneImage} alt='' style={{height: '400px', padding:'0px'}} />
                </Col>
            </Row>   
            </ScrollAnimation>        
        </div>
        );
    }
}
 
export default Product;