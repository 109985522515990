import React, { Component } from "react";
import "antd/dist/antd.css";
import "./index.css";
import Col from "antd/es/col";
import Row from "antd/es/row";

import { Layout } from "antd";
import { animateScroll as scroll } from "react-scroll";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./home";
import Headers from "./header";
import Product from "./product";
import Product2 from "./product2";
import Product3 from "./product3";
import Product4 from "./product4";
import Contact from "./contact";
import Privacy from "./privacy";
import "antd/dist/antd.css";
import TermAndConditions from "./terms-and-condition";

const { Content, Footer } = Layout;

class Onepager extends Component {
    state = {
        current: "1",
        blockBox: false
    };
    scrollToTop = () => {
        scroll.scrollToTop();
    };
    showBlock = () => {
        this.setState({ blockBox: true });
    };
    hideBlock = e => {
        this.setState({ blockBox: false });
    };
    handleClick = () => {
        console.log("gas");
    };
    product = () => {
        this.setState({ current: ["2"] });
        this.props.history.push("/");
    };
    render() {
        return (
            <Layout className="layout" style={{ background: "white" }}>
                <Router>
                    <Headers />
                    <Switch>
                        <Route path={["/privacy"]}>
                            <Privacy />
                        </Route>
                        <Route path={["/terms-condition"]}>
                            <TermAndConditions />
                        </Route>
                        <Route path={["/"]}>
                            <Content style={{ padding: "0px 0px" }}>
                                <Row>
                                    <Col xl={{ span: 20, offset: 2 }}>
                                        <Home id="home" />
                                    </Col>
                                </Row>
                                <Product id="product" />
                                <Product2 id="product2" />
                                <Product3 id="product3" />
                                <Product4 id="product4" />
                            </Content>
                        </Route>
                    </Switch>
                    <Contact id="contact" />
                </Router>

                <Footer style={{ textAlign: "center", padding: "30px" }}>
                    ©2019 Onelabs.co
                </Footer>
            </Layout>
        );
    }
}

export default Onepager;
