import React, { Component } from 'react'
import { Typography } from 'antd';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";
import 'antd/dist/antd.css';

import phoneImage from '../../assets/images/artboard_2.png'
const { Title } = Typography;

class Product extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            width: window.innerWidth
        }
    }
    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);
    }
    updateDimensions = () => {
		if (window.innerWidth >= 576) {
			this.setState({
				width: window.innerWidth
			});
		} else {
			this.setState({
				width: window.innerWidth
			});
        }
	}
    render() { 
        return (
            <div style={{ background: '#5bbcff', padding: '50px 14px 0px 14px', minHeight: '', textAlign: 'left' }} id={this.props.id}>
            <Row gutter={24}>
                <ScrollAnimation animateIn="fadeInRight" animateOnce> 
                <Col xl={{ span: 0, offset: 0 }} md={{ span: 0, offset: 0 }} sm={{ span: 0, offset: 0 }} xs={{ span: 18, offset: 3 }} className={this.state.width >= 576 ? 'wrap-product' : 'wrap-product-sm'} >
                    <Title level={4} style={{color: 'white'}}>
                    Get On-Time Payment <br /> Information 💳
                    </Title>
                    <p style={{marginTop: '10px', fontSize: '16px'}}>
                    Don't be afraid to miss your kid's school payments again because now, you can get school payment information and notification in a timely and detailed manner.
                    </p>
                </Col>
                   
                <Col xl={{ span: 9, offset: 2 }} md={{ span: 11, offset: 1 }} sm={{ span: 11, offset: 1 }} xs={{ span: 20, offset: 2 }} style={{textAlign: 'right'}}> 
                    <img src={phoneImage} alt='' style={{width: '100%', padding:'0px'}} />
                </Col>
               
                <Col xl={{ span: 9, offset: 0 }} md={{ span: 9, offset: 0 }} sm={{ span: 10, offset: 0 }} xs={{ span: 0, offset: 0 }} className={this.state.width >= 876 ? 'wrap-product' : this.state.width <= 875 ? 'wrap-product-md' : 'wrap-product-sm'}>
                    <Title level={4} style={{color: 'white'}}>
                    Get On-Time Payment <br /> Information 💳
                    </Title>
                    <p style={{marginTop: '10px', fontSize: '16px'}}>
                    Don't be afraid to miss your kid's school payments again because now, you can get school payment information and notification in a timely and detailed manner.
                    </p>
                </Col>
                </ScrollAnimation>
            </Row>           
        </div>
        );
    }
}
 
export default Product;