import React, { Component } from "react";
import "antd/dist/antd.css";
// import './../index.scss';
import Col from "antd/es/col";
import Row from "antd/es/row";
// import { animateScroll as scroll } from "react-scroll"
import Typography from "antd/es/typography";
import "antd/dist/antd.css";
const { Title } = Typography;

class TermsAndCondition extends Component {
    render() {
        return (
            <div className="term-and-conditions">
                <Row>
                    <Col
                        xl={{ span: 20, offset: 2 }}
                        lg={{ span: 22, offset: 1 }}
                        md={{ span: 22, offset: 1 }}
                        sm={{ span: 22, offset: 1 }}
                        xs={{ span: 22, offset: 1 }}
                        className="text-footer"
                        style={{ textAlign: "left", padding: "50px 20px" }}
                    >
                        <Title level={2}>Syarat dan Ketentuan</Title>
                        <b
                            style={{
                                padding: 0,
                                color: "#6308ff",
                                fontWeight: "lighter"
                            }}
                        >
                            Tanggal Berlaku: 1 Januari 2020
                        </b>
                        <br />
                        <br />
                        <p>
                            Harap membaca Syarat dan Ketentuan terkait Program
                            Rilis Terbatas Fasilitas Aplikasi PintuKelas untuk
                            Peningkatan Kepuasan Produk ("
                            <b>Syarat dan Ketentuan PintuKelas</b>") ini dengan
                            cermat. Syarat dan Ketentuan PintuKelas ini
                            merupakan syarat-syarat dan ketentuan-ketentuan yang
                            berlaku secara umum selama program rilis terbatas
                            atas fasilitas aplikasi PintuKelas yang disediakan
                            oleh PT Olah Cipta Bimasena Caraka Nusantara
                            (onelabs.co)
                        </p>
                        <Title level={4}>
                            <ol className="title-one">
                                <li>
                                    <strong>
                                        Partisipasi dalam Program Rilis Terbatas
                                        Fasilitas Aplikasi PintuKelas untuk
                                        Peningkatan Kepuasan Produk dan/atau
                                        Layanan Bank
                                    </strong>
                                </li>
                            </ol>
                        </Title>
                        <div className="content">
                            <p>
                                Tujuan Program Rilis Terbatas Fasilitas Aplikasi
                                PintuKelas untuk Peningkatan Kepuasan Produk
                                adalah untuk membuat fasilitas aplikasi
                                pra-rilis, layanan pra-rilis, dan informasi
                                terkait aplikasi PintuKelas (secara kolektif
                                disebut PintuKelas) tersedia bagi Anggota
                                peserta Program Rilis Terbatas (secara kolektif
                                disebut Pengguna) dari waktu ke waktu untuk
                                tujuan memberikan umpan balik kepada OneLabs.co
                                tentang kualitas dan ketepatgunaan PintuKelas
                                dalam rangka meningkatkan kepuasan atas produk
                                dan layanan PintuKelas dalam menunjang produk
                                dan/atau layanan OneLabs.co yang digunakan
                                Pengguna. OneLabs.co berhak mengubah syarat,
                                ketentuan, dan kebijakan Program Rilis Terbatas
                                ini dari waktu ke waktu, dan mencabut
                                partisipasi Pengguna dalam Program Rilis
                                Terbatas ini kapan saja. Jika OneLabs.co membuat
                                perubahan pada Syarat dan Ketentuan ini, maka
                                OneLabs.co akan memberitahukan perubahan
                                tersebut kepada Anggota PintuKelas secara
                                tertulis.
                            </p>
                            <p>
                                Dengan berpartisipasi dalam Program Rilis
                                Terbatas, Anggota PintuKelas menyatakan bahwa
                                Pengguna secara hukum diizinkan untuk bergabung
                                dalam Program Rilis Terbatas. Perjanjian dan
                                Syarat dan Ketentuan PintuKelas ini tidak
                                berlaku apabila dilarang oleh hukum dan hak
                                untuk menjadi Pengguna tidak diberikan dalam
                                yurisdiksi tersebut. Kecuali jika disetujui atau
                                diizinkan oleh OneLabs.co secara tertulis,
                                Anggota PintuKelas tidak akan membagikan atau
                                mentransfer fasilitas aplikasi PintuKelas atau
                                materi apa pun yang Anggota PintuKelas terima
                                dari OneLabs.co sehubungan dengan menjadi
                                Pengguna. Sekalipun ada pembatasan sebelumnya
                                dalam Bagian 1 ini, Anggota PintuKelas dapat
                                membagikan atau mentransfer fasilitas aplikasi
                                PintuKelas atau materi apapun yang Anggota
                                PintuKelas terima dari OneLabs.co kepada
                                Anggota, yang merupakan pengguna aplikasi
                                PintuKelas, untuk penggunaan sehubungan dengan
                                Program Rilis Terbatas semata-mata di bawah
                                pengawasan Anggota PintuKelas dan hanya menurut
                                Syarat dan Ketentuan PintuKelas ini. Anggota
                                PintuKelas bertanggung jawab atas kepatuhan dan
                                pelanggaran Anggota PintuKelas sendiri serta
                                terhadap Syarat dan Ketentuan PintuKelas serta
                                ketentuan OneLabs.co lainnya.
                            </p>
                        </div>
                        <Title level={4}>2. Akses Penggunaan PintuKelas</Title>
                        <div className="content">
                            <p>
                                Untuk dapat menggunakan Layanan secara
                                penuh,Anda harus terlebih dahulu terdaftar
                                menjadi anggota PintuKelas.Anda harus terlebih
                                dahulu mendaftarkan diri anda dengan menghubungi
                                halo@pintukelas.com.Anda tidak diperkenankan
                                untuk menggunakan akun pihak lain tanpa izin.
                                Seluruh penggunaan aplikasi PintuKelas harus
                                sesuai dengan Syarat dan Ketentuan PintuKelas
                                ini.
                            </p>
                        </div>
                        <Title level={4}>
                            3. Pemberian dan Pembatasan PintuKelas
                        </Title>
                        <div className="content">
                            <p>
                                OneLabs.co dengan ini memberikan fasilitas
                                kepada Pengguna yang tunduk dan patuh pada
                                Syarat dan Ketentuan PintuKelas untuk
                                menggunakan PintuKelas sebagai pelengkap produk
                                dan/atau layanan OneLabs.co yang digunakan
                                semata-mata untuk tujuan pengujian dan evaluasi
                                serta hanya sehubungan dengan Program Rilis
                                Terbatas ini. Kecuali sebagaimana diijinkan lain
                                dalam Bagian 10, fasilitas ini tidak memberi
                                Pengguna hak untuk menggunakan PintuKelas untuk
                                tujuan lain apa pun atau untuk mengungkapkan,
                                mereproduksi, mendistribusikan, memodifikasi,
                                atau membuat karya turunan dari PintuKelas.
                                Anggota PintuKelas setuju untuk tidak
                                mendekompilasi, merekayasa balik, membongkar,
                                mendekripsi, atau berupaya mendapatkan source
                                code dari PintuKelas (kecuali jika dan hanya
                                sejauh pembatasan sebelumnya dilarang oleh hukum
                                yang berlaku, atau sejauh yang diijinkan oleh
                                persyaratan lisensi yang mengatur penggunaan
                                komponen open-sourced yang disertakan dengan
                                PintuKelas). Anggota PintuKelas menjamin bahwa
                                PintuKelas hanya akan digunakan untuk tujuan
                                pengujian dan evaluasi sehubungan dengan Program
                                Rilis Terbatas dan tidak akan disewakan, dijual,
                                disewa-guna-usahakan, disublisensikan,
                                diberikan, didistribusikan atau ditransfer.
                                OneLabs.co menguasai kepemilikan penggunaan
                                PintuKelas, dan kecuali sebagaimana ditentukan
                                secara tersurat di sini, tidak ada hak atau
                                fasilitas yang akan diberikan atau
                                diimplikasikan di bawah kekayaan intelektual
                                PintuKelas apa pun.
                            </p>
                        </div>
                        <Title level={4}>
                            4. Umpan Balik dan Komunikasi dari OneLabs.co
                        </Title>
                        <div className="content">
                            <p>
                                Sebagai bagian dari Program Rilis Terbatas,
                                OneLabs.co akan memberi Pengguna kesempatan
                                untuk mengirimkan laporan bug/isu, pertanyaan,
                                usulan peningkatan fitur, dan/atau informasi
                                dukungan lainnya (secara kolektif, "
                                <b>Umpan Balik</b>") kepada OneLabs.co.
                                OneLabs.co dapat meminta informasi ini dari
                                Pengguna melalui surel, kuesioner daring,
                                formulir bug dan/atau isu, wawancara melalui
                                telepon dan/atau tatap muka dan mekanisme
                                lainnya. Dengan menyetujui Syarat dan Ketentuan
                                PintuKelas ini, Anggota PintuKelas menyetujui
                                bahwa OneLabs.co dapat menghubungi Pengguna dari
                                waktu ke waktu tentang Program Rilis Terbatas,
                                dan Anggota PintuKelas dengan ini menyetujui
                                untuk menerima komunikasi tersebut. Kecuali jika
                                dinyatakan lain dalam Bagian 8, Anggota
                                PintuKelas setuju bahwa dengan tidak adanya
                                perjanjian tertulis yang terpisah untuk,
                                sebaliknya, OneLabs.co dapat bebas menggunakan
                                Umpan Balik apa pun yang Pengguna berikan untuk
                                tujuan apa pun.
                            </p>
                        </div>
                        <Title level={4}>5. Definisi Informasi Rahasia</Title>
                        <div className="content">
                            <p>
                                Anggota PintuKelas setuju bahwa PintuKelas dan
                                informasi apa pun mengenai PintuKelas (termasuk
                                sifat dan keberadaan, fitur, fungsi, dan screen
                                shot-nya) dan informasi apa pun lainnya yang
                                diungkapkan oleh OneLabs.co kepada Pengguna
                                sehubungan dengan Program Rilis Terbatas akan
                                dipertimbangkan dan dirujuk dalam Syarat dan
                                Ketentuan PintuKelas ini sebagai "
                                <b>Informasi Rahasia</b>". Informasi yang tidak
                                dianggap sebagai Informasi Rahasia adalah (a)
                                secara umum dan sah tersedia untuk umum tanpa
                                kesalahan atau pelanggaran dari Anggota
                                PintuKelas dan/atau Anggota, (b) secara umum
                                disediakan kepada publik oleh OneLabs.co (c)
                                dikembangkan secara independen oleh Anggota
                                PintuKelas dan/atau Anggota tanpa menggunakan
                                Informasi Rahasia apa pun, (d) diperoleh secara
                                sah dari pihak ketiga yang memiliki hak untuk
                                mentransfer atau mengungkapkannya kepada Anggota
                                PintuKelas dan/atau Anggota tanpa batasan, atau
                                (e) perangkat lunak pihak ketiga dan/atau
                                dokumentasi yang diberikan kepada Anggota
                                PintuKelas dan/atau Anggota oleh OneLabs.co dan
                                disertai dengan ketentuan lisensi yang tidak
                                memaksakan kewajiban kerahasiaan atas penggunaan
                                atau pengungkapan perangkat lunak dan/atau
                                dokumentasi tersebut tidak akan dianggap
                                Informasi Rahasia berdasarkan Syarat dan
                                Ketentuan PintuKelas ini. Semua Informasi
                                Rahasia tetap menjadi milik tunggal OneLabs.co
                                dan Pengguna tidak memiliki lisensi tersirat
                                atau hak lain dalam Informasi Rahasia yang tidak
                                ditentukan dalam Syarat dan Ketentuan PintuKelas
                                ini.
                            </p>
                        </div>
                        <Title level={4}>
                            6. Pelarangan Penggunaan dan Pengungkapan Informasi
                            Rahasia
                        </Title>
                        <div className="content">
                            <p>
                                Kecuali sebagaimana diijinkan secara tegas dalam
                                Bagian 6 ini, Anggota PintuKelas setuju bahwa
                                Pengguna tidak akan mengungkapkan,
                                mempublikasikan, atau menyebarkan Informasi
                                Rahasia apa pun kepada siapa pun selain individu
                                yang terdaftar sebagai Pengguna, atau jika
                                sebaliknya secara tegas diijinkan atau disetujui
                                secara tertulis oleh OneLabs.co. Anggota
                                PintuKelas selanjutnya setuju untuk mengambil
                                tindakan pencegahan yang wajar untuk mencegah
                                penggunaan, pengungkapan, publikasi, atau
                                penyebaran Informasi Rahasia yang tidak sah,
                                termasuk mencegah akses atau demonstrasi
                                PintuKelas kepada pihak ketiga. Anggota
                                PintuKelas setuju untuk menggunakan Informasi
                                Rahasia hanya untuk penggunaan yang diijinkan
                                sebagaimana diatur dalam Syarat dan Ketentuan
                                PintuKelas ini. Anggota PintuKelas setuju untuk
                                tidak menggunakan Informasi Rahasia untuk
                                kepentingan Anggota PintuKelas sendiri atau
                                Pengguna atau kepentingan pihak ketiga mana pun
                                tanpa persetujuan tertulis sebelumnya dari
                                perwakilan resmi OneLabs.co dalam setiap
                                kesempatan. Anggota PintuKelas dengan ini
                                mengakui bahwa pengungkapan atau penggunaan
                                Informasi Rahasia tanpa ijin dapat menyebabkan
                                kerugian pada OneLabs.co yang mungkin sulit
                                ditentukan nilainya. Dengan demikian, Anggota
                                PintuKelas setuju bahwa OneLabs.co akan memiliki
                                hak untuk segera meminta ganti rugi untuk
                                menegakkan kewajiban berdasarkan Syarat dan
                                Ketentuan PintuKelas selain hal-hal dan tindakan
                                pemulihan lainnya yang mungkin OneLabs.co
                                miliki.
                            </p>
                        </div>
                        <Title level={4}>
                            7. Tindakan Pencegahan dalam Penggunaan PintuKelas
                        </Title>
                        <div className="content">
                            <p>
                                Anggota PintuKelas memahami bahwa aplikasi
                                PintuKelas tidak berada pada tingkat kinerja
                                atau kompatibilitas yang sama seperti aplikasi
                                lain yang tersedia untuk umum. Ada kemungkinan
                                PintuKelas tidak dapat dijalankan secara optimal
                                dan mungkin saja dapat terjadi kesalahan atau
                                ketidakakuratan yang menyebabkan kegagalan,
                                kerusakan atau kehilangan data dan/atau
                                informasi yang dimasukkan ke dalam PintuKelas.
                                Jika hal tersebut terjadi, maka PintuKelas dapat
                                dimodifikasi secara substansial sebelum
                                peluncuran resmi perdana, atau penarikan
                                fasilitas. PintuKelas disediakan "
                                <b>SEBAGAIMANA ADANYA</b>" tanpa jaminan dalam
                                bentuk apa pun. Seluruh risiko yang timbul dari
                                penggunaan atau kinerja PintuKelas adalah
                                tanggung jawab Pengguna. OneLabs.co tidak
                                bertanggung jawab atas segala hal yang mungkin
                                timbul dari penggunaan atau ketidakmampuan
                                aplikasi PintuKelas, bahkan sekalipun jika
                                OneLabs.co telah diberitahu tentang kemungkinan
                                kerusakan tersebut. OneLabs.co sangat
                                menganjurkan Pengguna untuk membuat salinan dan
                                menyimpan semua data dan informasi di gawai,
                                komputer dan/atau perangkat apa pun milik
                                Anggota PintuKelas dan/atau Anggota sebelum
                                berpartisipasi dalam Program Rilis Terbatas.
                            </p>
                        </div>
                        <Title level={4}>
                            8. Persetujuan untuk Pengumpulan dan Penggunaan Data
                        </Title>
                        <div className="content">
                            <p>
                                Untuk menguji dan meningkatkan kinerja produk
                                dan layanan PintuKelas, Anggota PintuKelas
                                mengakui bahwa OneLabs.co dapat mengumpulkan
                                segala informasi terkait penggunaan aplikasi
                                PintuKelas dari gawai ataupun periferal milik
                                pribadi Pengguna ataupun perangkat lainnya yang
                                menggunakan PintuKelas, hanya jika Pengguna
                                memilih untuk mengunduh dan menggunakan
                                PintuKelas yang disediakan sebagai bagian dari
                                Program Rilis Terbatas. Anggota PintuKelas
                                mengakui dan setuju bahwa OneLabs.co memiliki
                                izin Pengguna untuk mengumpulkan setiap dan
                                semua informasi tersebut dan menggunakannya
                                sebagaimana disebutkan di atas.
                            </p>
                            <p>
                                Data Pengguna yang dikumpulkan sesuai dengan
                                Bagian 8 ini akan diperlakukan sesuai dengan
                                Kebijakan Privasi PintuKelas, yang digabungkan
                                dengan referensi ke dalam Syarat dan Ketentuan
                                PintuKelas yang dapat diunduh di halaman Profil
                                pada aplikasi PintuKelas.
                            </p>
                        </div>
                        <Title level={4}>
                            9. Tanpa Dukungan dan Pemeliharaan; Produk Ke Depan
                        </Title>
                        <div className="content">
                            <p>
                                Selama Anggota PintuKelas berpartisipasi dalam
                                Program Rilis Terbatas, OneLabs.co tidak
                                berkewajiban untuk memberi pemeliharaan,
                                dukungan teknis, ataupun hal lainnya pada
                                Pengguna di luar dari Syarat dan Ketentuan
                                PintuKelas. Anggota PintuKelas mengakui bahwa
                                OneLabs.co tidak memiliki kewajiban tersurat
                                maupun tersirat untuk mengumumkan atau
                                menyediakan versi komersial PintuKelas kepada
                                siapa pun setelah periode Program Rilis Terbatas
                                selesai. Jika versi komersial tersedia, ada
                                kemungkinan terdapat perbedaan fitur atau fungsi
                                dari versi PintuKelas yang disediakan untuk
                                Program Rilis Terbatas ini.
                            </p>
                        </div>
                        <Title level={4}>10. Tanpa Jaminan</Title>

                        <div className="content">
                            <p>
                                Fasilitas aplikasi PintuKelas yang tersedia
                                selama Program Rilis Terbatas dapat
                                diindikasikan sebagai versi beta, pengembangan,
                                atau pra-rilis yang belum sepenuhnya diuji. Oleh
                                karena itu, ada kemungkinan aplikasi PintuKelas
                                masih belum sempurna dan terdapat
                                ketidakakuratan yang dapat menyebabkan kegagalan
                                dan/atau hilangnya data atau informasi milik
                                Pengguna. Anggota PintuKelas secara tegas
                                mengakui dan setuju bahwa sejauh diizinkan oleh
                                undang-undang yang sah dan berlaku, semua
                                penggunaan aplikasi PintuKelas menjadi risiko
                                Pengguna sendiri dan seluruh risiko atas
                                kualitas, kinerja, akurasi dan upaya yang
                                memuaskan ada pada Pengguna.
                            </p>
                            <p>
                                Anggota PintuKelas mengakui bahwa OneLabs.co
                                belum mengumumkan secara terbuka ketersediaan
                                fasilitas aplikasi PintuKelas untuk umum dan
                                belum menjanjikan atau memberi jaminan kepada
                                Anggota PintuKelas bahwa fasilitas aplikasi
                                PintuKelas versi komersial akan diumumkan atau
                                tersedia untuk umum di masa depan. OneLabs.co
                                juga tidak memiliki kewajiban secara tersurat
                                maupun tersirat kepada Anggota PintuKelas untuk
                                mengumumkan atau memperkenalkan fasilitas
                                aplikasi PintuKelas versi komersial, fasilitas
                                yang serupa/kompatibel, ataupun untuk terus
                                menawarkan akses penggunaan aplikasi PintuKelas
                                versi pra-rilis di masa mendatang.
                            </p>
                        </div>

                        <Title level={4}>11. Penyangkalan Kewajiban</Title>
                        <div className="content">
                            <p>
                                Selama tidak bertentangan dengan undang-undang
                                yang sah dan berlaku, Anggota PintuKelas
                                mengakui bahwa Pengguna bertanggung jawab atas
                                semua risiko, biaya, bunga, dan kerusakan atau
                                kerugian dalam segala jenis dan bentuk yang
                                terkait dengan pemasangan atau penggunaan
                                aplikasi PintuKelas sebagaimana diatur dalam
                                Syarat dan Ketentuan PintuKelas, termasuk tanpa
                                kecuali biaya salinan data, biaya terkait
                                penggunaan aplikasi PintuKelas pada gawai
                                Pengguna, risiko kehilangan data, cidera
                                pribadi, gangguan emosi, serta kehilangan
                                barang, pendapatan, keuntungan, penggunaan atau
                                keuntungan ekonomi lainnya. OneLabs.co tidak
                                akan bertanggung jawab atas kerugian,
                                kehilangan, dan kerusakan atau cidera apapun,
                                baik secara tidak langsung, khusus, insidental
                                atau pun konsekuensial, termasuk kewajiban apa
                                pun yang berasal dari setiap penggunaan aplikasi
                                PintuKelas pada gawai Pengguna yang terhubung
                                dengan dan/atau dari informasi rahasia lainnya,
                                dan/atau kinerja atau kegagalan performa
                                aplikasi PintuKelas, bahkan jika OneLabs.co
                                telah diberitahu tentang kemungkinan kegagalan
                                tersebut.
                            </p>
                        </div>
                        <Title level={4}>12. Hak Kekayaan Intelektual</Title>
                        <div className="content">
                            <p>
                                Anggota PintuKelas dengan alasan apapun, tanpa
                                persetujuan dari OneLabs.co, dilarang
                                menggunakan setiap logo, design, rancangan,
                                modifikasi, ide, dan hal-hal lain milik
                                OneLabs.co ("Hak Kekayaan Intelektual"). Dalam
                                hal terdapat pelanggaran, maka OneLabs.co berhak
                                mengajukan klaim atau tuntutan termasuk denda
                                atau sanksi lainnya kepada Anggota PintuKelas.
                            </p>
                        </div>
                        <Title level={4}>
                            13. Perangkat Lunak dan Informasi Pihak Ketiga
                        </Title>
                        <div className="content">
                            <p>
                                Bagian-bagian dari aplikasi PintuKelas dapat
                                mencakup perangkat lunak dan informasi pihak
                                ketiga. Pengakuan, persyaratan lisensi, dan
                                penyangkalan materi tersebut terdapat pada
                                aplikasi PintuKelas, dan penggunaan materi
                                tersebut oleh Pengguna diatur dalam persyaratan
                                tersebut. Penyebutan pihak ketiga dan produk
                                pihak ketiga dalam materi atau promo marketing
                                apa pun yang disediakan untuk Pengguna hanya
                                untuk tujuan informasi dan bukan merupakan
                                dukungan atau rekomendasi. Semua spesifikasi dan
                                deskripsi produk pihak ketiga disediakan oleh
                                masing-masing vendor atau penyedia, dan
                                OneLabs.co tidak akan bertanggung jawab
                                sehubungan dengan pemilihan, kinerja, serta
                                penggunaan vendor atau produk ini. Semua
                                pemahaman, perjanjian, atau jaminan jika ada,
                                terjadi langsung antara vendor dan calon
                                pengguna.
                            </p>
                        </div>
                        <Title level={4}>
                            14. Hukum yang Berlaku dan Penyelesaian Perselisihan
                        </Title>
                        <div className="content">
                            <p>
                                Syarat dan Ketentuan PintuKelas ini tunduk
                                terhadap hukum Negara Republik Indonesia.
                                Apabila terjadi perselisihan yang timbul dari
                                dan sehubungan dengan pelaksanaan Syarat dan
                                Ketentuan PintuKelas ("<b>Perselisihan</b>"),
                                maka akan diselesaikan secara musyawarah dan
                                mufakat.
                            </p>
                            <p>
                                Jika Perselisihan tersebut tidak dapat
                                diselesaikan dalam waktu 14 (empat belas) hari
                                kerja sejak disampaikannya pemberitahuan secara
                                tertulis, maka Perselisihan tersebut akan
                                diselesaikan melalui Pengadilan Negeri Jakarta
                                Selatan. Selama Perselisihan dalam proses
                                penyelesaian, Anggota PintuKelas diwajibkan
                                tetap melaksanakan kewajibannsa berdasarkan
                                Syarat dan Ketentuan PintuKelas ini.
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default TermsAndCondition;
